/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { useParams, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'
import swal from 'sweetalert2'

import { useModals, useSetState } from '@campaignhub/react-hooks'
import { Box, LoadingModule, MainContent, ModalContext } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'

import { getEntityByName } from '@functions/getEntityByName'
import { groupBy } from '@functions/groupBy'
import { saveDocxFile } from '@functions/copyDocxFile'

import useReduxAction from '@hooks/useReduxAction'
import useCurrentUser from '@hooks/useCurrentUser'
import useServiceJob from '@hooks/useServiceJob'
import useServiceJobUsers from '@hooks/useServiceJobUsers'
import useUploadAsset from '@hooks/useUploadAsset'
import useUploadAssetVersion from '@hooks/useUploadAssetVersion'
import useUsers from '@hooks/useUsers'
import useServiceJobUserRoles from '@hooks/useServiceJobUserRoles'

import AssetClientModal from '@modals/AssetClientModal'
import AssetEditorModal from '@modals/AssetEditorModal'
import AssetHistoryModal from '@modals/AssetHistoryModal'
import AssetQCModal from '@modals/AssetQCModal'
import AssetSettingsModal from '@modals/AssetSettingsModal'
import AssetTemplateOverrideModal from '@modals/AssetTemplateOverrideModal'
import UploadVersionModal from '@modals/UploadVersionModal'

import AssetSection from './components/AssetSection'
import Header from './components/Header'

const assignServiceJobStatus = (serviceJobParam, createFn, setState) => {
  createFn(serviceJobParam).then(({ errors, success }) => {
    if (!success && errors) {
      toast.warning(errors[0])
    }
  })

  setState({ copyFieldDisabled: false })
}

const updateAsset = (assetParams, updateFn, createFn, saveDocxParams, saveDocx, setState, savingSwalInstance) => {
  setState({ copyFieldDisabled: true })
  updateFn(assetParams).then(({ success, errors }) => {
    if (!success && errors) {
      return toast.warning(errors[0])
    }
    if (saveDocx) {
      const { serviceJob, details, updatedCopyDetails, assetFile } = saveDocxParams
      const updateAssetCallback = () => {
        savingSwalInstance.close()
        setState({ updatedAsset: true })
      }
      saveDocxFile(serviceJob, details, updatedCopyDetails, createFn, updateFn, assetFile, updateAssetCallback, true)
      setState({ copyFieldDisabled: false })
    }
  })
}

const callbacks = (component, setState, isMobileDevice) => {
  const componentCallbacks = {
    AssetClientModal: {
      closeModal: () => setState({ showAssetClientModal: false }),
    },
    AssetEditorModal: {
      closeModal: () => setState({ showAssetEditorModal: false }),
    },
    AssetHistoryModal: {
      closeModal: () => setState({ showAssetHistoryModal: false }),
    },
    AssetQCModal: {
      closeModal: () => setState({ showAssetQCModal: false }),
    },
    AssetSettingsModal: {
      closeModal: isMobileDevice ? () => setState({ showAssetSettingsModal: false }) : null,
    },
    AssetTemplateOverrideModal: {
      closeModal: () => setState({ showAssetTemplateOverrideModal: false }),
    },
    AssetUploadVersionModal: {
      closeModal: () => setState({ showAssetUploadVersionModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  assignedUserRole: [],
  clientComments: [],
  copyFieldDisabled: true,
  fileComments: [],
  fullscreen: true,
  groupedAssetComments: [],
  isMobileDevice: false,
  markers: [],
  proofingNotes: [],
  refreshSas: 0,
  refreshServiceJob: 0,
  sasToken: '',
  selectedAsset: {},
  selectedAssetVersionCount: 0,
  showAllComments: false,
  showAssetClientModal: false,
  showAssetEditorModal: false,
  showAssetHistoryModal: false,
  showAssetQCModal: false,
  showAssetSettingsModal: false,
  showAssetTemplateOverrideModal: false,
  showAssetUploadVersionModal: false,
  showMarkers: true,
  timestamps: [],
  updatedAsset: false,
  updatedCopyDetails: [],
}

const FullAssetScreen = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    assignedUserRole,
    clientComments,
    copyFieldDisabled,
    fileComments,
    fullscreen,
    groupedAssetComments,
    isMobileDevice,
    markers,
    proofingNotes,
    refreshSas,
    refreshServiceJob,
    sasToken,
    selectedAsset,
    selectedAssetVersionCount,
    showAllComments,
    showAssetClientModal,
    showAssetEditorModal,
    showAssetHistoryModal,
    showAssetQCModal,
    showAssetSettingsModal,
    showAssetTemplateOverrideModal,
    showAssetUploadVersionModal,
    showMarkers,
    timestamps,
    updatedAsset,
    updatedCopyDetails,
  } = state

  const { id: serviceJobId } = useParams()

  const [searchParams] = useSearchParams()
  const assetId = parseInt(searchParams.get('assetId'), 10)

  const { currentUser } = useCurrentUser()
  const { users } = useUsers()
  const { serviceJobUserRoles } = useServiceJobUserRoles()

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const serviceJobIncludes = ['service', 'campaign', 'client', 'details', 'histories', 'postProductionChecklistItems', 'users']
  useReduxAction('serviceJobs', 'loadServiceJobV1', {
    includes: serviceJobIncludes.join(','),
  }, [serviceJobId, refreshServiceJob], {
    dispatchAction: (action, requestOptions) => action(serviceJobId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loadedIds } = entityReducer
      return serviceJobId && (!loadedIds.includes(serviceJobId) || refreshServiceJob !== 0)
    },
  })

  const assetIncludes = ['comments.createdBy', 'histories.createdBy, file.filePreviewRequests']
  useReduxAction('assets', 'loadAssets', {
    serviceJobs: serviceJobId,
    includes: assetIncludes.join(','),
  }, [serviceJobId, assetId])

  const {
    callbacks: {
      assignServiceJobStatus: createFn,
    },
    serviceJob,
  } = useServiceJob({ id: serviceJobId })
  const { filteredServiceJobUsers } = useServiceJobUsers(serviceJob.id)

  useReduxAction('containerSas', 'loadContainerSas', serviceJob.campaignId, [refreshSas], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loading } = entityReducer
      return !loading && !errors.length && serviceJob.campaignId && refreshSas
    },
  })

  const containerSasReducer = useSelector(reduxState => reduxState.containerSas)
  const { result: containerSas } = containerSasReducer

  const useUploadAssetPayload = useUploadAsset()
  const {
    callbacks: {
      createAsset: createFnAsset,
      updateAsset: updateFnAsset,
    },
    creating: creatingAsset,
    updating: updatingAsset,
  } = useUploadAssetPayload

  const useUploadAssetVersionPayload = useUploadAssetVersion()
  const { callbacks: { updateAssetDirect: updateFnAssetDirect } } = useUploadAssetVersionPayload

  const entities = useSelector(reduxState => reduxState.entities)
  const { assets, assetComments, assetCommentTypes, serviceGroups, serviceJobDetails, serviceJobStatuses, wordReplacements } = entities

  const copyFileAsset = Object.values(assets).find(x => x.serviceJobId === parseInt(serviceJobId, 10) && x.file !== null)

  const { loading } = useSelector(reduxState => reduxState.assets)
  const details = Object.values(serviceJobDetails).filter(x => serviceJob?.details?.includes(x.id))

  useEffect(() => {
    setState({
      selectedAsset: assets[(updatedAsset || showAssetUploadVersionModal) ? selectedAsset?.id : assetId],
      updatedCopyDetails: [],
    })
    if (updatedAsset) setState({ updatedAsset: false })
  }, [serviceJobId, assetId, assets])

  useEffect(() => {
    setState({ assignedUserRole: filteredServiceJobUsers?.filter(x => x.user.id === currentUser.id) })
  }, [serviceJobId, filteredServiceJobUsers])

  useEffect(() => {
    const comments = Object.values(assetComments).filter(x => x.assetId === selectedAsset?.id)
      ?.filter((x => x.details.length))

    if (selectedAsset && Object.keys(selectedAsset)?.length) {
      const markerFilter = comments?.reduce((filtered, comment) => {
        if (comment.details?.some(x => x.entityFieldType?.name === 'Position')) {
          filtered.push({
            ...comment,
            left: comment.assetCommentTypeId === getEntityByName(assetCommentTypes, 'ClientComment')?.id
              ? JSON.parse(comment.details.find(x => x.entityFieldType.name === 'Position')?.value).x - 1.3
              : JSON.parse(comment.details.find(x => x.entityFieldType.name === 'Position')?.value).x,
            top: comment.assetCommentTypeId === getEntityByName(assetCommentTypes, 'ClientComment')?.id
              ? JSON.parse(comment.details.find(x => x.entityFieldType.name === 'Position')?.value).y - 2.5
              : JSON.parse(comment.details.find(x => x.entityFieldType.name === 'Position')?.value).y,
            commentIndex: filtered.length + 1,
          })
        }
        return filtered
      }, []) /* converted to reduce instead of map because of error cause by comment having empty details array */

      const timestampFilter = comments?.reduce((filtered, comment) => {
        if (comment.details?.some(x => x.entityFieldType?.name === 'Timestamp')) {
          filtered.push({
            ...comment,
            timestamp: JSON.parse(comment.details[0]?.value).value,
            commentIndex: filtered.length + 1,
          })
        }
        return filtered
      }, []) /* converted to reduce instead of map because of error cause by comment having empty taildes array */

      const fileFilter = comments?.reduce((filtered, comment) => {
        if (comment.details?.some(x => x.entityFieldType?.name === 'FileDetails')) {
          const parsedData = JSON.parse(comment.details.find(x => x.entityFieldType.name === 'FileDetails')?.value)
          const fileDetails = JSON.parse(parsedData.value)

          filtered.push({
            ...comment,
            uri: decodeURIComponent(fileDetails.Uri),
            name: fileDetails.FileName,
            type: fileDetails.FileType,
          })
        }
        return filtered
      }, []) /* converted to reduce instead of map because of error cause by comment having empty taildes array */

      const clientFilter = comments?.reduce((filtered, comment) => {
        if (comment.assetCommentTypeId === getEntityByName(assetCommentTypes, 'ClientComment')?.id) {
          const item = markerFilter.find(x => x.id === comment.id) || timestampFilter.find(x => x.id === comment.id) || fileFilter.find(x => x.id === comment.id)
          filtered.push({
            ...comment,
            commentIndex: item && item?.commentIndex ? item.commentIndex : -1,
          })
        }
        return filtered
      }, [])

      const proofingFilter = comments?.reduce((filtered, comment) => {
        if (comment.assetCommentTypeId === getEntityByName(assetCommentTypes, 'ProofingNote')?.id) {
          filtered.push({
            ...comment,
            commentIndex: filtered.length + 1,
          })
        }
        return filtered
      }, [])

      const versions = [...new Map(selectedAsset.histories?.map(item => [item.version, item.version])).values()]

      setState({
        clientComments: clientFilter ? [...clientFilter] : [],
        fileComments: fileFilter ? [...fileFilter] : [],
        markers: markerFilter ? [...markerFilter] : [],
        proofingNotes: proofingFilter ? [...proofingFilter] : [],
        selectedAssetVersionCount: versions.length,
        timestamps: timestampFilter ? [...timestampFilter] : [],
      })
    }
  }, [selectedAsset, assetComments])

  useEffect(() => {
    if (['Video', 'VidMo'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)) {
      setState({ groupedAssetComments: groupBy(timestamps, 'status') })
    } else {
      setState({ groupedAssetComments: groupBy(markers, 'status') })
    }
  }, [markers, timestamps])

  const updateCopyDetails = (asset, copyDetailId, value) => {
    const assetToUpdate = asset

    const { copy } = assetToUpdate
    const copyToUpdate = copy.copyDetails.find(x => x.id === copyDetailId)
    copyToUpdate.value = value

    const temporaryAssets = updatedCopyDetails
    const assetIndex = temporaryAssets.findIndex(x => x.id === asset.id)
    if (assetIndex >= 0) {
      temporaryAssets[assetIndex] = assetToUpdate
    } else {
      temporaryAssets.push(assetToUpdate)
    }

    setState({ updatedCopyDetails: temporaryAssets })
  }

  const saveCopyChanges = (type) => {
    const assetFile = Object.values(assets).sort((a, b) => b.file?.id - a.file?.id).find(asset => asset.serviceJobId === serviceJob.id && asset.file)
    let savingSwalInstance = null

    if (updatedCopyDetails?.length)
      savingSwalInstance = swal.fire({
        title: 'Saving',
        icon: 'warning',
        text: 'We\'re saving your changes and generating your file. Please do not close this window.',
        allowOutsideClick: false,
        didOpen: () => {
          swal.showLoading()
        }
      })

    if (type === 'scan') {
      updatedCopyDetails.map((asset) => {
        asset.copy.copyDetails.map((x) => {
          let replaced = x.value

          Object.values(wordReplacements).map((word) => {
            const re = new RegExp(`(?<!\\w)(${word.input})(?!\\w)`, 'g')

            replaced = replaced.replace(re, word.output)
          })

          if (replaced !== x.value) {
            updateCopyDetails(asset, x.id, replaced)
          }
        })
      })
    }

    updatedCopyDetails.map((asset, index) => {
      const formData = new FormData()
      formData.append('Description', asset.description)
      formData.append('CopyTemplateId', asset.copy.copyTemplateId)
      formData.append('CopyDetails', JSON.stringify(asset.copy.copyDetails))

      const assetParams = {
        id: asset.id,
        data: formData,
      }

      const filledCopies = Object.values(assets).filter(x => x.copy?.copyDetails.some(y => y.value !== null && y.value !== ''))

      const saveDocxParams = {
        serviceJob,
        details,
        updatedCopyDetails: [...updatedCopyDetails, ...filledCopies.filter(x => !updatedCopyDetails?.map(y => y.id).includes(x.id))],
        assetFile,
      }

      updateAsset(assetParams, updateFnAsset, createFnAsset, saveDocxParams, updatedCopyDetails.length === index + 1, setState, savingSwalInstance)
    })
  }

  const enableCopyEditing = () => {
    const serviceJobParam = {
      id: serviceJob.id,
      serviceJobStatusId: getEntityByName(serviceJobStatuses, 'InProduction').id,
      workflowComment: 'Updated from CH Copy Assets',
      assets: Object.values(assets).filter(asset => asset.serviceJobId === serviceJob.id && asset.copy?.id).map(x => x.id),
    }

    if (getEntityByName(serviceJobStatuses, 'InProduction').id > serviceJob.serviceJobStatusId && copyFieldDisabled) {
      assignServiceJobStatus(serviceJobParam, createFn, setState)
    } else {
      setState({ copyFieldDisabled: !copyFieldDisabled })
    }
  }

  const assetVideo = useRef(null)

  const pageContext = {
    assetVideo,
    assignedUserRole,
    currentUser,
    callbacks: {
      showAssetClientModal: (payload) => {
        setModalData('AssetClientModal', payload)
        setState({ showAssetClientModal: true })
      },
      showAssetEditorModal: (payload) => {
        setModalData('AssetEditorModal', payload)
        setState({ showAssetEditorModal: true })
      },
      showAssetHistoryModal: (payload) => {
        setModalData('AssetHistoryModal', payload)
        setState({ showAssetHistoryModal: true })
      },
      showAssetQCModal: (payload) => {
        setModalData('AssetQCModal', payload)
        setState({ showAssetQCModal: true })
      },
      showAssetSettingsModal: (payload) => {
        setModalData('AssetSettingsModal', payload)
        setState({ showAssetSettingsModal: true })
      },
      showAssetTemplateOverrideModal: (payload) => {
        setModalData('AssetTemplateOverrideModal', payload)
        setState({ showAssetTemplateOverrideModal: true })
      },
      showAssetUploadVersionModal: (payload) => {
        setModalData('AssetUploadVersionModal', payload)
        setState({ showAssetUploadVersionModal: true })
      },
      toggleCopyFieldState: () => enableCopyEditing(),
      toggleFullScreen: () => setState({ fullscreen: !fullscreen, showAssetSettingsModal: !showAssetSettingsModal }),
      toggleSaveCopy: type => saveCopyChanges(type),
      toggleServiceJobRefresh: () => setState({ refreshServiceJob: refreshServiceJob + 1 }),
      toggleShowAllComments: () => setState({ showAllComments: !showAllComments }),
      toggleShowMarkers: () => setState({ showMarkers: !showMarkers }),
      toggleUpdateCopyDetails: (asset, copyDetailId, value) => updateCopyDetails(asset, copyDetailId, value),
      toggleUpdateSelectedAsset: currentAsset => setState({ selectedAsset: currentAsset }),
    },
    clientComments,
    copyFieldDisabled,
    details,
    creatingAsset,
    fileComments,
    fullscreen,
    groupedAssetComments,
    histories: copyFileAsset?.histories || [],
    isMobileDevice,
    markerSetState: setState,
    markers,
    proofingNotes,
    sasToken,
    selectedAsset,
    selectedAssetVersionCount,
    serviceJob,
    showAllComments,
    showMarkers,
    updatingAsset,
    users,
    serviceJobUserRoles,
  }

  useEffect(() => {
    setState({ fullscreen: !fullscreen, showAssetSettingsModal: !showAssetSettingsModal })
  }, [])

  useEffect(() => {
    if (fullscreen) {
      setState({
        showAssetClientModal: false,
        showAssetEditorModal: false,
        showAssetHistoryModal: false,
        showAssetQCModal: false,
        showAssetUploadVersionModal: false,
        showAssetTemplateOverrideModal: false,
      })
    }
  }, [fullscreen])

  useEffect(() => {
    if (serviceJob?.campaignId) {
      let storedValue = sessionStorage.getItem(serviceJob.campaignId)

      if (storedValue) {
        const urlParams = new URLSearchParams(storedValue)
        const tokenExpiry = decodeURIComponent(urlParams.get('se'))

        if (DateTime.fromISO(tokenExpiry, { zone: 'utc' }) < DateTime.now().toUTC()) {
          sessionStorage.removeItem(serviceJob.campaignId)
          storedValue = null
        }
      }

      if (!storedValue && !containerSas) setState({ refreshSas: refreshSas + 1 })
      else if (!storedValue && containerSas) {
        sessionStorage.setItem(serviceJob.campaignId, containerSas)
        setState({ sasToken: containerSas })
      } else setState({ sasToken: storedValue })
    }
  }, [serviceJob.campaignId, containerSas])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <MainContent
          offset={{ left: 0, top: 71 }}
          width={!isMobileDevice && fullscreen ? '100%' : 'calc(100% - 375px)'}
          style={{ display: 'flex', flexDirection: 'column', paddingBottom: 0 }}
        >
          <Header assetPayload={state} />

          {(loading || !assetId) && (
            <Box flexDirection="column" marginTop="large" paddingX="large">
              <LoadingModule loading={loading || !assetId} times={3} />
            </Box>
          )}

          {(!loading || !assetId) && <AssetSection />}
        </MainContent>
        <AssetClientModal
          callbacks={callbacks('AssetClientModal', setState)}
          showModal={showAssetClientModal}
        />
        <AssetEditorModal
          callbacks={callbacks('AssetEditorModal', setState)}
          showModal={showAssetEditorModal}
        />
        <AssetHistoryModal
          callbacks={callbacks('AssetHistoryModal', setState)}
          showModal={showAssetHistoryModal}
        />
        <AssetQCModal
          callbacks={callbacks('AssetQCModal', setState, null)}
          showModal={showAssetQCModal}
        />
        <AssetTemplateOverrideModal
          callbacks={callbacks('AssetTemplateOverrideModal', setState)}
          showModal={showAssetTemplateOverrideModal}
        />
        <AssetSettingsModal
          callbacks={callbacks('AssetSettingsModal', setState, isMobileDevice)}
          disableAnimation
          disableOverlay
          showModal={showAssetSettingsModal}
        />
        <UploadVersionModal
          callbacks={{
            ...callbacks('AssetUploadVersionModal', setState, null),
            updateDirectFn: updateFnAssetDirect,
          }}
          displayAllowedFileTypes
          serviceJob={serviceJob}
          serviceJobOptions={{ asset: selectedAsset, serviceJobId: serviceJob.id }}
          showModal={showAssetUploadVersionModal}
          title="Upload"
          titleSecondLine="Asset Versions"
          type="Assets"
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default FullAssetScreen
