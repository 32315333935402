/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { useEffect, useRef, useMemo } from 'react'
import { toast } from 'react-toastify'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import ExifReader from 'exifreader'

import { storageAccountName } from '@functions/api'

const initializeUppy = (options) => {
  const {
    allowedFileTypes,
    callbacks,
    currentUserId,
    fileServiceContainer,
    maxFileSize,
    requestOptions = {},
  } = options

  const errorList = []
  const uploadedList = []

  const { sasToken, container, id: fileServiceContainerId } = fileServiceContainer || {}
  const { serviceJobId } = requestOptions

  const { toggleAddedResource } = callbacks

  const processFile = async (file, uppy) => {
    const filePath = `${serviceJobId}/resources`
    const blobUrl = encodeURI(`https://${storageAccountName}.blob.core.windows.net/${container}/${filePath}/${file.meta.name}`)

    let tags = `userId=${currentUserId}&containerId=${fileServiceContainerId}`
    tags = `${tags}&resourceTypeId=${requestOptions.resourceTypeId}`

    uppy.setFileMeta(file.id, {
      ...requestOptions,
      fileServiceContainerId,
      filePath,
      fileName: file.meta.name,
      blobUrl,
    })

    if (file.meta.type.startsWith('image/')){
      const arrayBuffer = await file.data.arrayBuffer()

      try {
        const metaKeys = [
          'ColorSpace',
          'DateTimeOriginal',
          'FNumber',
          'ExposureTime',
          'ImageLength',
          'ImageWidth',
          'ISOSpeedRatings',
          'Make',
          'Model',
          // jpg exif keys has spaces in between
          'Color Space',
          'Image Height',
          'Image Width',
        ]

        const exifTags = ExifReader.load(arrayBuffer)
        const filterExif = Object.fromEntries(
          metaKeys.map(key => [key, exifTags[key]]),
        )

        uppy.setFileState(file.id, {
          xhrUpload: {
            ...file.xhrUpload,
            endpoint: `${blobUrl}?${sasToken}`,
            headers: {
              'x-ms-blob-type': 'BlockBlob',
              'x-ms-blob-content-type': file.meta.type,
              'x-ms-meta-size': file.size,
              ...(filterExif?.DateTimeOriginal) && { 'x-ms-meta-datetimeoriginal': filterExif.DateTimeOriginal.description },
              ...(filterExif?.ExposureTime) && { 'x-ms-meta-exposuretime': `ISO ${filterExif.ExposureTime.description}` },
              ...(filterExif?.FNumber) && { 'x-ms-meta-fnumber': filterExif.FNumber.description },
              ...(filterExif?.ISOSpeedRatings) && { 'x-ms-meta-iso': filterExif.ISOSpeedRatings.description },
              ...(filterExif?.Make) && { 'x-ms-meta-make': filterExif.Make.description },
              ...(filterExif?.Model) && { 'x-ms-meta-model': filterExif.Model.description },
              ...(filterExif?.ColorSpace && !filterExif['Color Space']?.value) && { 'x-ms-meta-colorspace': filterExif.ColorSpace.description },
              ...(filterExif['Color Space']?.value) && { 'x-ms-meta-colorspace': filterExif['Color Space'].value },
              ...(filterExif['Image Height']?.value && filterExif['Image Width']?.value && !file.meta.type.endsWith('DNG'))
                && { 'x-ms-meta-dimensions': `${filterExif['Image Width'].value} x ${filterExif['Image Height'].value}` },
              ...(filterExif?.ImageLength && filterExif?.ImageWidth && !file.meta.type.endsWith('DNG')
                && (!filterExif['Image Height']?.value && !filterExif['Image Width']?.value))
                && { 'x-ms-meta-dimensions': `${filterExif.ImageWidth.value} x ${filterExif.ImageLength.value}` },
              'x-ms-tags': tags,
            },
          },
        })
      } catch (error){
        console.error('Error parsing EXIF data:', error)

        uppy.setFileState(file.id, {
          xhrUpload: {
            ...file.xhrUpload,
            endpoint: `${blobUrl}?${sasToken}`,
            headers: {
              'x-ms-blob-type': 'BlockBlob',
              'x-ms-blob-content-type': file.meta.type,
              'x-ms-tags': tags,
            },
          },
        })
      }
    } else {
      uppy.setFileState(file.id, {
        xhrUpload: {
          ...file.xhrUpload,
          endpoint: `${blobUrl}?${sasToken}`,
          headers: {
            'x-ms-blob-type': 'BlockBlob',
            'x-ms-blob-content-type': file.meta.type,
            'x-ms-tags': tags,
          },
        },
      })
    }
  }

  const uppy = new Uppy({
    id: `uppyUpload-${requestOptions.resourceTypeId}`,
    debug: false,
    autoProceed: false,
    restrictions: {
      allowedFileTypes,
      maxFileSize,
    },
  })
    .use(XHRUpload, {
      method: 'PUT',
      formData: false,
      fieldName: 'FormFile',
      getResponseError(){ },
      timeout: 0,
    })
    .on('restriction-failed', (file, error) => {
      if (error.message === 'Cannot add more files'){
        toast.warning(error.message)
      } else {
        errorList.push({ ...file, errorType: 'restricted', errorMessage: error.message })
      }
    })
    .on('files-added', (files) => {
      Object.values(files).map(file => processFile(file, uppy))
      toggleAddedResource(requestOptions.resourceTypeId)
    })
    .on('upload-success', (file) => {
      uploadedList.push(file)
    })
    .on('upload-error', (file) => {
      errorList.push({ ...file, errorType: 'failed', errorMessage: 'Failed to Upload' })
    })

  return uppy
}

const checkRequiredOptions = (options = []) => {
  const requiredOptions = ['allowedFileTypes', 'requestOptions', 'fileServiceContainer']

  return options.every(option => requiredOptions.every(key => Object.keys(option).includes(key)))
}

function useUppyUploadResource(options = []){
  const uppyRefs = useRef([])

  const uppyInstances = useMemo(() => {
    if (checkRequiredOptions(options)) return options.map(x => initializeUppy(x))
  }, [options])

  uppyRefs.current = uppyInstances

  useEffect(() => () => {
      uppyRefs.current.forEach(uppy => uppy.close())
    }, [])

  return uppyRefs.current
}

export default useUppyUploadResource
