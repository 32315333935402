import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import { Box, ListItem, Text } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

const SidebarList = () => {
  const { callbacks, serviceJob } = useContext(PageContext)
  const {
    showAssetClientModal, showAssetEditorModal, showAssetHistoryModal, showAssetQCModal, showAssetTemplateOverrideModal,
  } = callbacks || {}

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  return (
    <Box flexDirection="column">
      <ListItem
        boxProps={{ padding: 'large', borderBottom: '1px solid' }}
        onClick={showAssetEditorModal}
        showAngleIcon
      >
        <Text fontSize="small" color="bodyFontLightColor">Editor</Text>
      </ListItem>
      {!['Copywriting'].includes(serviceGroups[serviceJob?.serviceGroupId]?.name) ? (
        <ListItem
          boxProps={{ padding: 'large', borderBottom: '1px solid' }}
          onClick={showAssetQCModal}
          showAngleIcon
        >
          <Text fontSize="small" color="bodyFontLightColor">QC</Text>
        </ListItem>
      ) : (
        <ListItem
          boxProps={{ padding: 'large', borderBottom: '1px solid' }}
          onClick={showAssetHistoryModal}
          showAngleIcon
        >
          <Text fontSize="small" color="bodyFontLightColor">History</Text>
        </ListItem>
      )}
      <ListItem
        boxProps={{ padding: 'large', borderBottom: '1px solid' }}
        onClick={showAssetClientModal}
        showAngleIcon
      >
        <Text fontSize="small" color="bodyFontLightColor">Client</Text>
      </ListItem>
      {['Copywriting'].includes(serviceGroups[serviceJob?.serviceGroupId]?.name) ? (
        <ListItem
          boxProps={{ padding: 'large', borderBottom: '1px solid' }}
          onClick={showAssetTemplateOverrideModal}
          showAngleIcon
        >
          <Text fontSize="small" color="bodyFontLightColor">Template Override</Text>
        </ListItem>
      ) : null}
    </Box>
  )
}

export default SidebarList
