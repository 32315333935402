/* eslint-disable no-alert */
/* eslint-disable max-len */
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

import PageContext from '@contexts/pageContext'
import { useModals, useSetState } from '@campaignhub/react-hooks'
import { useParams } from 'react-router-dom'

import { Box, Columns, ModalContext } from '@campaignhub/suit-theme'

import serviceJobNoteTypes from '@functions/serviceJobNoteTypes'

import useCurrentUser from '@hooks/useCurrentUser'
import useReduxAction from '@hooks/useReduxAction'
import useServiceJob from '@hooks/useServiceJob'
import useUploadResource from '@hooks/useUploadResource'
import useUploadAsset from '@hooks/useUploadAsset'
import useResourceTypes from '@hooks/useResourceTypes'

import ChangeJobAssignmentModal from '@modals/ChangeJobAssignmentModal'
import ClientResourceModal from '@modals/ClientResourceModal'
import EditServiceJobDetailsModal from '@modals/EditServiceJobDetailsModal'
import EditResourceDetailModal from '@modals/EditResourceDetailModal'
import UploadModal from '@modals/UploadModal'
import UploadResourceModal from '@modals/UploadResourceModal'

import ServiceJobNote from '@components/ServiceJobNote'
import custom from '@styles/custom.module.scss'
import ActivityLogs from './components/ActivityLogs'
import AppointmentDetails from './components/AppointmentDetails'
import AssetsAndResources from './components/AssetsAndResources'
import AssignedUsers from './components/AssignedUsers'
import AvailableResources from './components/AvailableResources'
import ClientProductionNotes from './components/ClientProductionNotes'
import DuskPositional from './components/DuskPositional'
import JobDetails from './components/JobDetails'
import JobHeader from './components/JobHeader'
import JobSummary from './components/JobSummary'
import ListingDetails from './components/ListingDetails'
import ProductDetails from './components/ProductDetails'
import ProductionNotes from './components/ProductionNotes'
import ProviderChecklist from './components/ProviderChecklist'
import StudioFeedback from './components/StudioFeedback'

const assignServiceJobStatus = (serviceJobParam, createFn, updateWorkflow, onCompletion = null) => {
  createFn(serviceJobParam).then(({ errors, success }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }
    updateWorkflow(serviceJobParam.serviceJobStatusId)

    if (onCompletion) onCompletion()
  })
}

const assignServiceJobUser = (serviceJobUserParams, additionalParams, createFn, createFnServiceJobStatus, updateWorkflow, setState) => {
  createFn(serviceJobUserParams).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    if (success){
      if (additionalParams.toggleToAssigned){
        createFnServiceJobStatus({
          id: serviceJobUserParams.serviceJobId,
          serviceJobStatusId: additionalParams.assignedStatusId,
          workflowComment: 'Assigned',
        }).then(({ errorData, successData }) => {
          if (!successData && errorData){
            toast.warning(errorData[0])
            return
          }
          updateWorkflow(additionalParams.assignedStatusId)
        })
      }
    }

    setState({ showChangeJobAssignmentModal: false })
  })
}

const updateServiceJobUser = (serviceJobUser, updateFn, setState) => {
  updateFn(serviceJobUser).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showChangeJobAssignmentModal: false })
  })
}

const updateResource = (resource, updateFn, setState) => {
  updateFn(resource).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showEditResourceDetailModal: false })
  })
}

const callbacks = (component, props, setState, progressRef, uploadsRef, callback, createFnServiceJobStatus) => {
  const progress = progressRef
  const uploads = uploadsRef
  const componentCallbacks = {
    ChangeJobAssignmentModal: {
      closeModal: () => setState({ showChangeJobAssignmentModal: false }),
      assignServiceJobUser: (serviceJobUserParams, additionalParams, createFn) => assignServiceJobUser(
          serviceJobUserParams,
          additionalParams,
          createFn,
          createFnServiceJobStatus,
          callback,
          setState,
        ),
      updateServiceJobUser: (serviceJobUser, updateFn) => updateServiceJobUser(serviceJobUser, updateFn, setState),
    },
    ClientResourceModal: {
      closeModal: () => setState({ showClientResourceModal: false }),
    },
    EditServiceJobDetailsModal: {
      closeModal: () => setState({ showEditServiceJobDetailsModal: false }),
    },
    EditResourceDetailModal: {
      closeModal: () => setState({ showEditResourceDetailModal: false }),
      updateResource: (resource, updateFn) => updateResource(resource, updateFn, setState),
    },
    ResourceUploadModal: {
      checkProgress: (progressStatus) => {
        progress.current = progressStatus
      },
      checkUploadStatus: (uploadStatus) => {
        uploads.current = uploadStatus
      },
      closeModal: () => {
        if ((progress.current && progress.current !== 0 && uploads.current.length === 0) || (progress.current === 0 && uploads.current.length === 0)){
          if (window.confirm('This action will close the modal and interrupt the ongoing upload.\nDo you still wish to close?')){
            progress.current = undefined
            uploads.current = []
            setState({ showResourceUploadModal: false })
          }
        } else {
          progress.current = undefined
          uploads.current = []
          setState({ showResourceUploadModal: false })
        }
      },
    },
    AssetUploadModal: {
      checkProgress: (progressStatus) => {
        progress.current = progressStatus
      },
      checkUploadStatus: (uploadStatus) => {
        uploads.current = uploadStatus
      },
      closeModal: () => {
        if ((progress.current && progress.current !== 0 && uploads.current.length === 0) || (progress.current === 0 && uploads.current.length === 0)){
          if (window.confirm('This action will close the modal and interrupt the ongoing upload.\nDo you still wish to close?')){
            progress.current = undefined
            uploads.current = []
            setState({ showAssetUploadModal: false })
          }
        } else {
          progress.current = undefined
          uploads.current = []
          setState({ showAssetUploadModal: false })
        }
      },
      assignServiceJobStatus: (serviceJobParams, createFn) => assignServiceJobStatus(serviceJobParams, createFn, callback),
    },
    MultipleResourceUploadModal: {
      checkProgress: (progressStatus) => {
        progress.current = progressStatus
      },
      checkUploadStatus: (uploadStatus) => {
        uploads.current = uploadStatus
      },
      closeModal: () => {
        if ((progress.current && progress.current !== 0 && uploads.current.length === 0) || (progress.current === 0 && uploads.current.length === 0)){
          if (window.confirm('This action will close the modal and interrupt the ongoing upload.\nDo you still wish to close?')){
            progress.current = undefined
            uploads.current = []
            setState({ showMultipleResourceUploadModal: false })
          }
        } else {
          progress.current = undefined
          uploads.current = []
          setState({ showMultipleResourceUploadModal: false })
        }
      },
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  allowedResourceFileTypes: 'image/*',
  duskContent: '',
  duskTotalImage: 0,
  loaded: false,
  refreshClientDetails: 0,
  showAssetUploadModal: false,
  showChangeJobAssignmentModal: false,
  showClientResourceModal: false,
  showEditResourceDetailModal: false,
  showEditServiceJobDetailsModal: false,
  showMultipleResourceUploadModal: false,
  showResourceUploadModal: false,
}

const ServiceJob = (props) => {
  const { userType } = props

  const [state, setState] = useSetState(defaultState)
  const {
    allowedResourceFileTypes,
    duskContent,
    duskTotalImage,
    refreshClientDetails,
    showAssetUploadModal,
    showChangeJobAssignmentModal,
    showClientResourceModal,
    showEditResourceDetailModal,
    showEditServiceJobDetailsModal,
    showMultipleResourceUploadModal,
    showResourceUploadModal,
    workflowUpdated,
  } = state

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const { id: serviceJobId } = useParams()
  const { currentUser } = useCurrentUser()

  const includes = ['campaign', 'client', 'contentDirectProviders', 'details', 'histories', 'postProductionChecklistItems', 'service', 'users', 'notes', 'tags']
  useReduxAction('serviceJobs', 'loadServiceJobV1', {
    includes: includes.join(','),
  }, [serviceJobId], {
    dispatchAction: (action, requestOptions) => action(serviceJobId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loadedIds } = entityReducer
      return serviceJobId && !loadedIds.includes(serviceJobId)
    },
  })

  const updateDuskDetail = (content, totalImage) => {
    setState({ duskContent: content, duskTotalImage: totalImage })
  }

  const {
    callbacks: {
      assignServiceJobStatus: createFnServiceJobStatus,
    },
    serviceJob,
    filteredServiceResourceTypes,
    showDuskPositional,
    loading: serviceJobLoading,
  } = useServiceJob({ id: serviceJobId })
  const modalRequestOptions = { serviceJobId: serviceJob.id }

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups, serviceJobDetails, serviceJobs, serviceJobClientDetails } = entities

  useReduxAction('serviceJobClientDetails', 'loadServiceJobClientDetail', {}, [serviceJobId, refreshClientDetails], {
    dispatchAction: (action, requestOptions) => action(serviceJobId, requestOptions),
    shouldPerformFn: (entityReducer) => {
      const { loading } = entityReducer
      return !loading && serviceJobId && !serviceJobClientDetails[serviceJobId]
    },
  })

  const updateWorkflow = (statusId) => {
    serviceJob.serviceJobStatusId = statusId
    setState({ workflowUpdated: !workflowUpdated })
  }

  const useUploadResourcePayload = useUploadResource()
  const {
    callbacks: {
      deleteResource: deleteFnResource,
      createResourceDirect: createFnResourceDirect,
      scanFolder: scanFnFolder,
    },
  } = useUploadResourcePayload

  const useUploadAssetPayload = useUploadAsset()
  const {
    callbacks: {
      deleteAsset: deleteFnAsset,
      createAssetDirect: createFnAssetDirect,
    },
  } = useUploadAssetPayload

  const pageContext = {
    callbacks: {
      showChangeJobAssignmentModal: (payload) => {
        setModalData('ChangeJobAssignmentModal', payload)
        setState({ showChangeJobAssignmentModal: true })
      },
      showClientResourceModal: (payload) => {
        setModalData('ClientResourceModal', payload)
        setState({ showClientResourceModal: true })
      },
      showEditServiceJobDetailsModal: (payload) => {
        setModalData('EditServiceJobDetailsModal', payload)
        setState({ showEditServiceJobDetailsModal: true })
      },
      showEditResourceDetailModal: (payload) => {
        setModalData('EditResourceDetailModal', payload)
        setState({ showEditResourceDetailModal: true })
      },
      showResourceUploadModal: (payload) => {
        setModalData('showResourceUploadModal', payload)
        setState({ showResourceUploadModal: true })
      },
      showAssetUploadModal: (payload) => {
        setModalData('showAssetUploadModal', payload)
        setState({ showAssetUploadModal: true })
      },
      showMultipleResourceUploadModal: (payload) => {
        setModalData('showMultipleResourceUploadModal', payload)
        setState({ showMultipleResourceUploadModal: true })
      },
      assignServiceJobStatus: (serviceJobUserParams, createFn, onCompletion = null) => {
        assignServiceJobStatus(serviceJobUserParams, createFn, updateWorkflow, onCompletion)
      },
      refreshServiceJobClientDetails: () => setState({ refreshClientDetails: refreshClientDetails + 1}),
      toggleUpdateDuskDetail: (content, totalImage) => updateDuskDetail(content, totalImage),
    },
    currentUser,
    duskContent,
    duskTotalImage,
    serviceJob,
    serviceJobs,
    showDuskPositional,
    workflowUpdated,
  }

  useEffect(() => {
    setState({ allowedResourceFileTypes: serviceGroups[serviceJob.serviceGroupId]?.allowedResourceFileTypes })
  }, [serviceJob.serviceGroupId, serviceGroups])

  useResourceTypes()

  const campaignPhotoJobs = Object.values(serviceJobs)?.filter(x => x?.campaign?.id === serviceJob?.campaign?.id && ['Photography'].includes(serviceGroups[x?.serviceGroupId]?.name))
  const duskPositionalDetails = Object.values(serviceJobDetails).find(x => campaignPhotoJobs?.find(y => y?.details?.includes(x.id) && x.entityFieldType?.name === 'DuskPositional'))

  const details = Object.values(serviceJobDetails).filter(x => serviceJob?.details?.includes(x.id))
  const providerChecklist = details.filter(x => x.entityFieldType.name === 'ProviderChecklist')
  const productionNotes = details.filter(x => x.entityFieldType.name === 'ClientStudioNote')

  const progressRef = useRef()
  const uploadsRef = useRef()

  const resourceTypesOrder = ['DuskPositional', 'BracketDay', 'Flash', 'BracketDusk', 'Clones']

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <JobHeader campaign={serviceJob.campaign} details={details} />
        <Box paddingX="large" paddingTop={[112, 105]}>
          <Columns boxProps={{ marginTop: 'large' }} flexDirection={['column', 'column', 'row']}>
            <Columns.Main>
              <AssetsAndResources
                hideProof={['Copywriting'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)}
                resourceTypes={filteredServiceResourceTypes}
                serviceJob={serviceJob}
                showResourcesOnly={(userType === 'Onsite' && !['Copywriting'].includes(serviceGroups[serviceJob.serviceGroupId]?.name))
                  || !['Copywriting', 'Photography', 'Floorplan', 'Video', 'VidMo', 'Virtual Staging'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)}
                workflowUpdated={workflowUpdated}
              />
              <JobSummary
                campaign={serviceJob.campaign}
                client={serviceJob.client}
                details={details}
                totalAssets={serviceJob.totalAssets}
                serviceJobLoading={serviceJobLoading}
                showJobLength={userType === 'Onsite'}
              />
              <ClientProductionNotes
                clientProductionNotes={serviceJob.client?.productionNote}
                hidden={userType !== 'Onsite'}
              />
              {!serviceJobLoading && (
                <ProviderChecklist
                  providerChecklist={providerChecklist}
                  hidden={userType !== 'Onsite'}
                />
              )}
              {(['Copywriting'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)
                || (!['Copywriting'].includes(serviceGroups[serviceJob.serviceGroupId]?.name) && userType === 'Studio'))
                && (
                  <ProductionNotes productionNotes={productionNotes} />
                )}
              <AvailableResources hidden />
              <ServiceJobNote
                dashboardModule
                editable
                title="Provider Notes"
                serviceJobId={serviceJob.id}
                serviceJobNoteTypeId={serviceJobNoteTypes.provider.id}
              />
              {(showDuskPositional || ['Dusk Photography'].includes(serviceJob?.service?.name)) && (
                <DuskPositional serviceJobDetail={duskPositionalDetails?.value} serviceJobId={serviceJob.id} editable={showDuskPositional} />
              )}
              <StudioFeedback hidden />
              <ListingDetails hidden client={serviceJob.client} />
              <ProductDetails hidden service={serviceJob.service} details={details} />
              <Box flexDirection="column" variant="white" className={custom.scroll}>
                <ActivityLogs serviceJob={serviceJob} workflowUpdated={workflowUpdated} />
              </Box>
            </Columns.Main>
            <Columns.Sidebar>
              <JobDetails hidden={userType === 'Onsite'} />
              <AssignedUsers hidden={userType === 'Onsite'} />
              <AppointmentDetails dateService={serviceJob.dateService} details={details} />
            </Columns.Sidebar>
          </Columns>
        </Box>
        <EditServiceJobDetailsModal
          callbacks={callbacks('EditServiceJobDetailsModal', props, setState)}
          showModal={showEditServiceJobDetailsModal}
          serviceJob={serviceJob}
        />
        <ChangeJobAssignmentModal
          callbacks={callbacks('ChangeJobAssignmentModal', props, setState, progressRef, uploadsRef, updateWorkflow, createFnServiceJobStatus)}
          showModal={showChangeJobAssignmentModal}
          serviceJob={serviceJob}
        />
        <ClientResourceModal
          callbacks={callbacks('ClientResourceModal', props, setState)}
          showModal={showClientResourceModal}
        />
        <EditResourceDetailModal
          callbacks={callbacks('EditResourceDetailModal', props, setState)}
          showModal={showEditResourceDetailModal}
        />
        <UploadModal
          callbacks={{
            ...callbacks('ResourceUploadModal', props, setState, progressRef, uploadsRef, updateWorkflow),
            createDirectFn: createFnResourceDirect,
            scanFolderFn: scanFnFolder,
            deleteFn: deleteFnResource,
          }}
          serviceJob={serviceJob}
          serviceJobOptions={modalRequestOptions}
          showModal={showResourceUploadModal}
          title="Upload"
          titleSecondLine="Resources"
          type="Resources"
          resourceTypes={filteredServiceResourceTypes?.filter(x => !['BracketDay', 'BracketDusk', 'Clones', 'Flash'].includes(x.name))}
          fileTypesAllowed={allowedResourceFileTypes}
        />
        <UploadModal
          callbacks={{
            ...callbacks('AssetUploadModal', props, setState, progressRef, uploadsRef, updateWorkflow),
            createDirectFn: createFnAssetDirect,
            deleteFn: deleteFnAsset,
          }}
          serviceJob={serviceJob}
          serviceJobOptions={modalRequestOptions}
          showModal={showAssetUploadModal}
          title="Upload"
          titleSecondLine="Assets"
          type="Assets"
        />
        <UploadResourceModal
          callbacks={{
            ...callbacks('MultipleResourceUploadModal', props, setState, progressRef, uploadsRef),
            scanFolderFn: scanFnFolder,
            deleteFn: deleteFnResource,
          }}
          serviceJob={serviceJob}
          serviceJobOptions={modalRequestOptions}
          showModal={showMultipleResourceUploadModal}
          title="Upload"
          titleSecondLine="Resources"
          type="Resources"
          resourceTypes={filteredServiceResourceTypes
            ?.filter(x => x.name !== 'PhotographyImage')
            ?.sort((a, b) => resourceTypesOrder.indexOf(a.name) - resourceTypesOrder.indexOf(b.name))}
          fileTypesAllowed={allowedResourceFileTypes}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

ServiceJob.propTypes = {
  userType: PropTypes.string,
}

export default ServiceJob
