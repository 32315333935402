/* eslint-disable consistent-return */
import React, { useContext, useEffect } from 'react'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import { useSetState } from '@campaignhub/react-hooks'
import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, FormField, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import useCurrentUser from '@hooks/useCurrentUser'
import useServiceJob from '@hooks/useServiceJob'
import useUsers from '@hooks/useUsers'

const defaultState = {
  appointmentDate: new Date(),
  selectedProvider: null,
}

const EditServiceJobDetailsModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const [state, setState] = useSetState(defaultState)
  const { appointmentDate, selectedProvider } = state

  const { currentUser: { timeZone } } = useCurrentUser()

  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const serviceJobPayload = digObject(modalData, `${modalKey}`, {})
  const {
    callbacks: { patchServiceJob: patchFn },
    serviceJob,
  } = serviceJobPayload

  const { updating } = useServiceJob()

  const { users, loading } = useUsers({ userTypes: [4] })

  useEffect(() => {
    const serviceDate = DateTime.fromISO(serviceJob?.dateService, { zone: timeZone })
    const currentDate = new Date(serviceDate.toFormat('y'), serviceDate.toFormat('M') - 1, serviceDate.toFormat('d'), serviceDate.toFormat('H'), serviceDate.toFormat('m'), 0)

    setState({ appointmentDate: currentDate, selectedProvider: serviceJob?.serviceUsername })
  }, [serviceJob])

  const updateAppointmentDate = () => {
    const currentDate = DateTime.now().setZone(timeZone)
    const updatedAppointmentDate = currentDate.set({
      year: appointmentDate.getFullYear(),
      month: appointmentDate.getMonth() + 1,
      day: appointmentDate.getDate(),
      hour: appointmentDate.getHours(),
      minute: appointmentDate.getMinutes(),
      second: 0,
      millisecond: 0,
    }).toISO({ suppressMilliseconds: true })

    const payload = {
      id: serviceJob.id,
      dateService: updatedAppointmentDate, 
      serviceUsername: selectedProvider,
    }

    patchFn(payload).then(({ success, errors }) => {
      if (!success) toast.warning(errors[0])
      closeModal()
    })
  }

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header callbacks={callbacks} title="Update" titleSecondLine="Appointment Details" />
      <SidebarModal.Content>
        <Box flexDirection="column" marginBottom="large">
          <FormField direction="column">
            <Text color="bodyFontLightColor" fontSize="xsmall" paddingBottom="small">Appointment Date</Text>
            <DatePicker
              dateFormat="dd MMM yyy h:mm aa"
              onChange={(date) => setState({ appointmentDate: date})}
              selected={appointmentDate}
              showTimeSelect
              timeIntervals={15}
            />
          </FormField>
        </Box>
        <Box flexDirection="column" marginBottom="large">
          <FormField direction="column" marginBottom="large">
            <Text color="bodyFontLightColor" fontSize="xsmall" paddingBottom="small">Service User</Text>
            <select
              value={selectedProvider?.toLowerCase()}
              onChange={(e) => {
                setState({ selectedProvider: e.target.value || null })
              }}
            >
              <option value={null}>{loading ? 'Loading...' : 'No Provider'}</option>
              {Object.values(users)?.find((x) => x?.userIdentity?.ctUsername?.toLowerCase() === serviceJob?.serviceUsername?.toLowerCase())
                || serviceJob?.contentDirectProviders?.map((x) => x.serviceUserName?.toLowerCase())?.includes(serviceJob?.serviceUsername?.toLowerCase()) ? null : (
                  <option value={serviceJob?.serviceUsername?.toLowerCase()}>{serviceJob?.serviceUsername}</option>
              )}
              {serviceJob?.contentDirectProviders?.map((provider) => (
                <option value={provider?.serviceUserName?.toLowerCase()}>Content Direct {provider?.name} ({provider?.serviceUserName})</option>
              ))}
              {Object.values(users)?.filter((x) => x?.userRole?.userTypeId === 4 && x?.userIdentity?.ctUsername)
                ?.sort((a, b) => (a?.firstName?.toLowerCase() > b?.firstName?.toLowerCase() ? 1 : -1))
                ?.map((provider) => (
                  <option value={provider?.userIdentity?.ctUsername?.toLowerCase()}>{provider?.firstName} {provider?.lastName} ({provider?.userIdentity?.ctUsername})</option>
              ))}
            </select>
          </FormField>
        </Box>
      </SidebarModal.Content>
      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={closeModal}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle="primaryCreate"
          disabled={!appointmentDate}
          loading={updating}
          onClick={updateAppointmentDate}
          size="medium"
          style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
        >
          Update
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditServiceJobDetailsModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  serviceJob: PropTypes.object,
}

EditServiceJobDetailsModal.defaultProps = {
  modalKey: 'EditServiceJobDetailsModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditServiceJobDetailsModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
