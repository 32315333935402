/* eslint-disable max-len */
/* eslint-disable array-callback-return */
import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import isMobileBrowser from 'is-mobile'

import {
  Box, Button, IconWithMenu, Link, ListItem,
} from '@campaignhub/suit-theme'
import { useSetState } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'

import useCurrentUser from '@hooks/useCurrentUser'
import useEmailRequest from '@hooks/useEmailRequest'
import useServiceJob from '@hooks/useServiceJob'
import useUploadAsset from '@hooks/useUploadAsset'

import defaultEmailRequestState from '@models/emailRequest'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faCloudDownload, faPlus, faTrash } from '@fortawesome/pro-light-svg-icons'

import { getEntityByName } from '@functions/getEntityByName'
import { isValidEmail } from '@functions/validations'
import { saveDocxFile } from '@functions/copyDocxFile'

const loadServiceJobAssets = (serviceJobParam, loadFn) => {
  const assetIncludes = ['comments', 'file', 'histories']
  const options = {
    serviceJobs: serviceJobParam.id,
    includes: assetIncludes.join(','),
  }

  loadFn(options).then(({ errors, success }) => {
    if (!success && errors){
      toast.warning(errors[0])
    }
  })
}

const createInitialCopyAsset = (serviceJobParam, generateFn, loadFn) => {
  generateFn(serviceJobParam).then(({ errors, success }) => {
    if (!success && errors){
      toast.warning(errors[0])
    }

    loadServiceJobAssets(serviceJobParam, loadFn)
  })
}

const defaultState = {
  agent: '',
  assignedToJob: false,
  enableSendToClient: true,
  rhDetails: null,
  visibleList: [],
}

const Footer = (props) => {
  const {
    contents, selectedIds, showCreateAsset, showDownloadAssets, showGenerateAsset,
  } = props

  const [state, setState] = useSetState(defaultState)
  const { agent, assignedToJob, enableSendToClient, rhDetails, visibleList } = state

  const {
    callbacks: { assignServiceJobStatus, refreshServiceJobClientDetails },
    serviceJob,
  } = useContext(PageContext)

  const useUploadAssetPayload = useUploadAsset()
  const {
    callbacks: {
      deleteAsset: deleteFnAsset,
      launchAssetUploadModal,
      updateAsset: updateFnAsset,
      createAsset: createFnAsset,
    },
    deleting: assetDeleting,
    loading: loadingAssets,
  } = useUploadAssetPayload

  const {
    callbacks: {
      createEmailRequest: createEmailFn,
    },
  } = useEmailRequest()

  const { currentUser } = useCurrentUser()
  const {
    callbacks: {
      assignServiceJobStatus: createFn,
      createInitialCopyAsset: generateFn,
      loadServiceJobAssets: loadFn,
    },
    creating: creatingJob,
    filteredServiceJobUsers,
    updating,
  } = useServiceJob({ id: serviceJob.id })

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobClientDetails, serviceJobDetails, serviceJobStatuses, serviceGroups } = entities

  useEffect(() => {
    if (serviceJobDetails){
      const agentDetailId = serviceJob.details?.find(x => Object.values(serviceJobDetails).find(y => y.id === x && y.entityFieldType.name === 'Agent'))
      const rhDetailsId = serviceJob.details?.find(x => Object.values(serviceJobDetails).find(y => y.id === x && y.entityFieldType.name === 'RealStudiosDetails'))

      setState({
        agent: serviceJobDetails[agentDetailId]?.value || '',
        rhDetails: serviceJobDetails[rhDetailsId]?.value || null,
      })
    }
  }, [serviceJobDetails])

  const showEmailModal = (serviceJobParam) => {
    const copyProofFile = !showCreateAsset ? contents.sort((a, b) => b.file?.id - a.file?.id).find(x => x.file) : ''

    swal.fire({
      title: 'Send Email',
      html: `<div style="flex-direction:column;>
        <div style="flex-direction:row;">
          <span>To:</span>
          <input id="emailTo" class="swal2-input"
            style="margin: 10px auto 5px auto; font-size: 16px; font-family: inherit;"
          />
          <span style="font-size: 14px; color: #8c8c8e">Separate with ; for multiple emails</span>
        </div>
      </div>`,
      icon: 'info',
      showDenyButton: copyProofFile,
      showCancelButton: true,
      confirmButtonText: 'Send Now',
      denyButtonText: 'Send Manually',
      willOpen: () => {
        const emailTo = serviceJobClientDetails[serviceJob.id]?.emailList || ''

        swal.getHtmlContainer().querySelector('#emailTo').value = emailTo
      },
      preConfirm: () => {
        const emailTo = swal.getHtmlContainer().querySelector('#emailTo').value

        if (!copyProofFile && !showCreateAsset) swal.showValidationMessage('No generated file.')
        if (!emailTo) swal.showValidationMessage('Email is required.')
        if (emailTo && emailTo.split(';').find(x => !isValidEmail(x.trim()))){
          swal.showValidationMessage('Found an invalid email.')
        }
      },
    })
    .then((result) => {
      let onCompletion

      if (result.isConfirmed){
        const subject = `${serviceJob.service.name} proof for ${serviceJob.campaign.name}`

        const copyDocxUrl = copyProofFile?.assetUrl ? `Your <b>${serviceJob.service.name}</b> proof for <b>${serviceJob.campaign.name}</b> is ready.
          Please click on the link below to view.<br/>`
          + `Download: <a href=${copyProofFile?.assetUrl}>${subject}</a><br/><br/>` : ''

        const viewInGallery = serviceGroups[serviceJob.serviceGroupId]?.name !== 'Copywriting'
          ? `Your <b>${serviceJob.service.name}</b> proof for <b>${serviceJob.campaign.name}</b> is ready. You may view these now from your ${serviceJob.contentHouseClientProofingUrl ? 'content' : 'media'} gallery.<br/><br/>` : ''

        const rejectionUrl = rhDetails ? '' : serviceJob.contentHouseClientProofingUrl || serviceJob.contentHouseProofingUrl
          ? 'If you require changes in your proof, please click the link below:<br/>'
          + `<a href=${serviceGroups[serviceJob.serviceGroupId]?.name === 'Copywriting'
            ? serviceJob.contentHouseClientProofingUrl : serviceJob.contentHouseClientProofingUrl || serviceJob.contentHouseProofingUrl}>Request Changes</a><br/><br/>` : ''

        const body = `Hi <b>${agent ? JSON.parse(agent).name : ''}</b>,<br/><br/>${copyDocxUrl}${viewInGallery}${rejectionUrl}Thanks.`

        const emailTo = swal.getHtmlContainer().querySelector('#emailTo').value

        const emailToArray = []
        emailTo.split(';').map((x) => {
          if (isValidEmail(x.trim())){
            emailToArray.push({
              email: x.trim(),
              name: x.trim(),
            })
          }
        })

        const emailRequestParams = defaultEmailRequestState
        emailRequestParams.tos = emailToArray
        emailRequestParams.subject = subject
        emailRequestParams.body = body

        onCompletion = function (){
          createEmailFn(emailRequestParams).then(({ success, errors }) => {
            if (!success){
              toast.warning(errors)
            }
          })
        }
      }

      if (result.isDenied){
        onCompletion = function (){
          const url = copyProofFile?.assetUrl
          const link = document.createElement('a')
          link.href = url
          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
        }
      }

      if (!result.isDismissed){
        assignServiceJobStatus(serviceJobParam, createFn, onCompletion)
      }
    })
  }

  const sendSelected = (status, sendAll, sendEmail) => {
    const selectedAssets = sendAll ? contents.map(x => x.id) : selectedIds
    const serviceJobParam = {
      id: serviceJob.id,
      serviceJobStatusId: status.id,
      workflowComment: 'Updated from CH Assets',
      assets: selectedAssets,
    }

    const getStatusDisplay = () => {
      switch (status.name){
        case 'InQC1':
          return 'QC1'
        case 'InQC':
          return 'QC2'
        case 'InProduction':
          return !showCreateAsset ? 'copywriter' : 'editor'
        case 'SentToClient':
          return 'client'
        case 'SentForVerification':
          return 'verification'
        default: return 'status'
      }
    }

    const statusName = getStatusDisplay()

    if (sendEmail){
      if (serviceJobClientDetails[serviceJob.id]) {
        showEmailModal(serviceJobParam)
      }
      else {
        toast.warning('Failed to retrieve emails. Please try again.')
        refreshServiceJobClientDetails()
      }
    } else {
      swal.fire({
        title: `Send to ${statusName[0].toUpperCase() + statusName.substring(1)}`,
        html: `This action will deliver the asset to ${statusName}.`
          + '<br/>Do you wish to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#e2001a',
        showClass: 'slide-from-top',
      }).then(({ value }) => {
        if (value){
          assignServiceJobStatus(serviceJobParam, createFn)
        }
      })
    }
  }

  const confirmDelete = (deleteAll) => {
    const selectedAssets = deleteAll ? contents : selectedIds
    swal.fire({
      title: `Delete ${deleteAll ? '' : 'Selected'} Assets?`,
      text: serviceJob.serviceGroupId === 2
        ? `Are you sure you want to delete ${deleteAll ? '' : 'selected'} assets? Assets sent for client approval / Approved will not be removed from the client's library.`
        : `Are you sure you want to delete ${deleteAll ? '' : 'selected'} assets? Approved assets will not be removed from the client's library.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it.',
      confirmButtonColor: '#DD6B55',
    })
      .then(({ value }) => {
        if (value){
          selectedAssets.forEach((entity) => {
            const obj = deleteAll ? entity : { id: entity }
            deleteFnAsset(obj).then(({ success, errors }) => {
              if (!success){
                toast.warning(errors)
              }
            })
          })
        }
      })
  }

  useEffect(() => {
    let isAssigned = filteredServiceJobUsers?.find(x => x.user.id === currentUser.id)
    if (serviceGroups[serviceJob.serviceGroupId]?.name === 'Copywriting' && !isAssigned){
      const details = Object.values(serviceJobDetails).filter(x => serviceJob?.details?.includes(x.id))
      const serviceUser = details.find(x => x.entityFieldType.name === 'ServiceUsername')?.value || ''

      if (serviceUser !== ''){
        const ctUsername = currentUser.userIdentity?.ctUsername || ''
        const serviceUsername = JSON.parse(serviceUser).value
        isAssigned = serviceUsername.toLowerCase() === ctUsername.toLowerCase()
      }
    }
    setState({ assignedToJob: isAssigned })
  }, [serviceJob, filteredServiceJobUsers, serviceJobDetails])

  useEffect(() => {
    const list = []
    const adminRoles = ['ProductionManager'] // ToDo: add as property in userRole

    if (assignedToJob || adminRoles.includes(currentUser.userRole.name)){
      if (currentUser.serviceJobStatuses.find(x => x === getEntityByName(serviceJobStatuses, 'InQC1')?.id) || currentUser.serviceJobStatuses.find(x => x === getEntityByName(serviceJobStatuses, 'InQC')?.id)){
        list.push('qc')
      }
      if (currentUser.serviceJobStatuses.find(x => x === getEntityByName(serviceJobStatuses, 'InProduction')?.id)){
        list.push('editor')
      }
      if (currentUser.serviceJobStatuses.find(x => x === getEntityByName(serviceJobStatuses, 'SentToClient')?.id)){
        list.push('client')
      }
      if (currentUser.serviceJobStatuses.find(x => x === getEntityByName(serviceJobStatuses, 'SentForVerification')?.id)
        && serviceGroups[serviceJob.serviceGroupId]?.name !== 'Copywriting'){
        list.push('verification')
      }
    }

    setState({ visibleList: list })
  }, [assignedToJob, serviceJobStatuses])

  useEffect(() => {
    if (serviceJob?.workbaseReferenceId && !['Copywriting', 'Photography'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)){
      const selectedRequests = contents.filter(x => selectedIds.includes(x.id)).map(x => ({ id: x.id, previews: x.file?.filePreviewRequests }))
      const premiumNeededServices = ['Floorplan', 'Virtual Staging'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)
      const hasMissingPreview = selectedRequests?.some(f => !f?.previews?.some(x => x.imageType === 'Preview' && x.generatedAt))
      const hasMissingPremium = selectedRequests?.some(f => !f?.previews?.some(x => x.imageType === 'Premium' && x.generatedAt))

      setState({ enableSendToClient: !((premiumNeededServices && (hasMissingPreview && hasMissingPremium)) || hasMissingPreview) })
    }
  }, [serviceJob, selectedIds, contents])

  const isMobile = isMobileBrowser()
  const showDeleteAssets = (serviceGroups[serviceJob.serviceGroupId]?.name !== 'Copywriting'
    || (serviceGroups[serviceJob.serviceGroupId]?.name === 'Copywriting' && ['ProductionManager', 'QC', 'Specialist'].includes(currentUser?.userRole.name)))

  return (
    <ListItem
      boxProps={{
        borderTop: '1px solid',
        padding: 'large',
        lineHeight: '1.3',
        alignItems: 'center',
      }}
      flexDirection="row"
      style={{ cursor: 'pointer' }}
      disableHover
    >
      <Box flexDirection={['column', 'row']}>
        {showCreateAsset && (
          <Box
            alignItems="center"
            marginRight={[0, 'auto']}
            marginBottom={['small', 0]}
            justifyContent="flexStart"
            width="auto"
            flexDirection="column"
          >
            <Button
              buttonStyle="secondary"
              icon={<FontAwesomeIcon icon={faPlus} />}
              onClick={launchAssetUploadModal}
              size="medium"
            >
              Create Asset
            </Button>
          </Box>
        )}
        {showGenerateAsset && (
          <Box
            alignItems="center"
            marginRight={[0, 'auto']}
            marginBottom={['small', 0]}
            justifyContent="flexStart"
            width="auto"
            flexDirection="column"
          >
            <Button
              size="medium"
              buttonStyle="secondary"
              icon={<FontAwesomeIcon icon={faPlus} />}
              loading={creatingJob || loadingAssets}
              onClick={() => createInitialCopyAsset(serviceJob, generateFn, loadFn)}
            >
              Generate Asset
            </Button>
          </Box>
        )}
        {showDeleteAssets && (
          <Box
            alignItems="center"
            marginLeft={[0, 'auto']}
            marginBottom={['small', 0]}
            justifyContent="flexEnd"
            width="auto"
            flexDirection="column"
          >
            <Button
              size="medium"
              buttonStyle="secondary"
              disabled={showCreateAsset ? !selectedIds.length : !contents.length}
              loading={assetDeleting}
              icon={<FontAwesomeIcon icon={faTrash} />}
              onClick={() => confirmDelete(!showCreateAsset)}
            >
              Delete {showCreateAsset && 'Selected'} Assets
            </Button>
          </Box>
        )}
        {showDownloadAssets && (
          <Box
            alignItems="center"
            marginLeft={[0, 'medium']}
            marginBottom={['small', 0]}
            justifyContent="flexEnd"
            width="auto"
            flexDirection="column"
          >
            <Button
              size="medium"
              buttonStyle="secondary"
              icon={<FontAwesomeIcon icon={faCloudDownload} />}
            >
              Download Assets
            </Button>
          </Box>
        )}
        <Box
          alignItems="center"
          marginLeft={[0, showDeleteAssets ? 'medium' : 'auto']}
          marginBottom={['small', 0]}
          justifyContent="flexEnd"
          width="auto"
          flexDirection="column"
        >
          <IconWithMenu
            icon={(
              <Button
                buttonStyle="ghostEdit"
                disabled={(showCreateAsset ? !selectedIds.length : !contents.length) || (!visibleList.length)}
                icon={<FontAwesomeIcon icon={faAngleUp} />}
                iconPosition="right"
                loading={updating}
                size="medium"
              >
                Send
              </Button>
            )}
            boxProps={{ width: ['100%', 'auto'] }}
          >
            <IconWithMenu.Menu
              closeOnClick
              listStyle={{ right: 0, left: `${isMobile ? '-1' : '-55'}px`, top: `-${(38 - visibleList.length) * visibleList.length}px` }}
              nibStyle={{ top: `${(23.5 + visibleList.length) * visibleList.length}px`, transform: 'rotate(225deg)' }}
            >
              {visibleList.includes('qc') && (
                <Link onClick={() => {
                  sendSelected(getEntityByName(serviceJobStatuses, serviceJob.serviceJobStatusId === getEntityByName(serviceJobStatuses, 'InQC1')?.id ? 'InQC' : 'InQC1'), !showCreateAsset)
                  if (serviceGroups[serviceJob.serviceGroupId]?.name === 'Copywriting'){
                    const copyProofFile = contents.find(x => x.file)
                    if (copyProofFile){
                      saveDocxFile(serviceJob, serviceJobDetails, {}, createFnAsset, updateFnAsset, copyProofFile, () => { }, false)
                    }
                  }
                }}
                >
                  {serviceJob.serviceJobStatusId === getEntityByName(serviceJobStatuses, 'InQC1')?.id ? 'Send to QC2' : 'Send to QC1'}
                </Link>
              )}
              {visibleList.includes('editor') && (
                <Link onClick={() => sendSelected(getEntityByName(serviceJobStatuses, 'InProduction'), !showCreateAsset)}>
                  Send to {!showCreateAsset ? 'Copywriter' : 'Editor'}
                </Link>
              )}
              {visibleList.includes('verification') && (
                <Link onClick={() => sendSelected(getEntityByName(serviceJobStatuses, 'SentForVerification'), !showCreateAsset)}>
                  Send to Specialist
                </Link>
              )}
            </IconWithMenu.Menu>
          </IconWithMenu>
        </Box>
        {visibleList.includes('client') && (
          <Box
            alignItems="center"
            marginLeft={[0, 'medium']}
            marginBottom={['small', 0]}
            justifyContent="flexEnd"
            width="auto"
            flexDirection="column"
          >
            <Button
              buttonStyle="ghostEdit"
              disabled={(showCreateAsset ? !selectedIds.length : !contents.length) || (!visibleList.length) || !enableSendToClient}
              iconPosition="right"
              loading={updating}
              onClick={() => sendSelected(getEntityByName(serviceJobStatuses, 'SentToClient'), !showCreateAsset, rhDetails !== null || serviceJob?.workbaseReferenceId)}
              size="medium"
              title={!enableSendToClient && selectedIds.length > 0 ? 'Generating previews. Please refresh after a few seconds.' : ''}
            >
              Send to Client
            </Button>
          </Box>
        )}
      </Box>
    </ListItem>
  )
}

Footer.propTypes = {
  contents: PropTypes.array,
  selectedIds: PropTypes.array,
  showCreateAsset: PropTypes.bool,
  showDownloadAssets: PropTypes.bool,
  showGenerateAsset: PropTypes.bool,
}

export default Footer
