import { isValidEmail } from '@functions/validations'
import zoneState from '@models/Client/zone'

const state = {
  id: null,
  name: '',
  business_name: '',
  brand: '',
  address: {
    address: '',
    suburb: '',
    state: '',
    postcode: '',
  },
  phone: '',
  email: '',
  zone: zoneState,
  jobtrack_reference: '',
  realhub_reference: '',
  classification: '',
  appointment_note: '',
  production_note: '',
  blacklisted_providers: [],
  account_managers: [],
  flags: [],
  proofing_emails: [],
  calendar_invite_emails: [],
}

export const requiredFields = [
  { key: 'name' },
  { key: 'email', validation: isValidEmail, invalidMessage: 'Invalid Email format', allowNullOrEmpty: true },
]

export default state
