import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import { useSetState, useDebounce } from '@campaignhub/react-hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus, faTimes } from '@fortawesome/pro-light-svg-icons'
import useReduxAction from '@hooks/useReduxAction'
import useUsersV3 from '@hooks/useUsersV3'

import {
  Box, FormField, DashboardModule, Tag, Text, InputSearch,
} from '@campaignhub/suit-theme'

import { isValidEmail } from '@functions/validations'

const defaultState = {
  calendarInviteEmail: '',
  proofingEmail: '',
  providersSelected: [],
  providerSelected: [],
  searchFilter: '',
  listCount: 5,
  selectedService: [],
  selectedRole: [],
  selectedEmployment: [],
  reset: false,
}

const mapResults = (filteredUsers, providersSelected) => {
  const results = Object.values(filteredUsers)?.filter(x => !providersSelected.map(y => y.reference).includes(x.reference))

    return {
      groups: [
        {
          key: 'Providers',
          title: 'Providers',
          items: results.map((result) => {
            const { name, reference } = result

            return {
              ...result,
              key: reference,
              text: name,
            }
          }),
        },
    ],
  }
}

const Preferences = (props) => {
  const { clientForm } = props

  const [state, setState] = useSetState(defaultState)
  const { calendarInviteEmail, proofingEmail, providersSelected, providerSelected, searchFilter, selectedService, selectedRole,
          selectedEmployment, reset, listCount } = state

  const debouncedSearchFilter = useDebounce(searchFilter, 300)

  const {
    entityState,
    entityState: {
      blacklisted_providers,
      calendar_invite_emails,
      proofing_emails,
    },
    setEntityState,
  } = clientForm

  useEffect(() => {
    setState({ searchFilter: debouncedSearchFilter })
  }, [debouncedSearchFilter])

  const searchFilterString = `"name":"${searchFilter.replace(/[^a-zA-Z0-9 ]/g, '')}%"`
  const roleString = '"roles*": "Provider"'
  const urlComponent = (`{${searchFilter.length > 0 ? `${searchFilterString},` : ''}
                          ${`${roleString},`}}`).replace(/\s+ /g, '')

  const options = {
    filter: (urlComponent !== '{}') ? urlComponent : '',
    size: 5,
    orderBy: '{ \'name\': \'asc\'}',
  }

  useReduxAction('usersV3', 'loadUsersV3', options, [searchFilter, debouncedSearchFilter])

  const { filteredUsersV3, usersV3 } = useUsersV3({
    searchFilter,
    selectedService,
    selectedRole,
    selectedEmployment,
    reset,
    listCount,
  })

  const toggleProvider = (provider) => {
    setState({ providerSelected: Object.values(providersSelected).includes(provider) ? [] : provider })
    setState({ providersSelected: Object.values(providersSelected).includes(provider)
      ? providersSelected.filter(x => x !== provider) : [...providersSelected, provider] })

    // remove provider to blacklisted_providers array
    if (blacklisted_providers.findIndex(x => provider.reference?.includes(x)) !== -1
        && Object.values(providersSelected).includes(provider)){
      blacklisted_providers.splice(blacklisted_providers.findIndex(x => provider.reference?.includes(x)), 1)
    }
  }

  const handleAddEmails = (email, type) => {
    if (isValidEmail(email)){
      if (type === 'calendarInvite'){
        const updateInviteEmails = [...new Set([...calendar_invite_emails, email])]
        setEntityState({ calendar_invite_emails: updateInviteEmails })
        setState({ calendarInviteEmail: '' })
      } else {
        const updateProofingEmails = [...new Set([...proofing_emails, email])]
        setEntityState({ proofing_emails: updateProofingEmails })
        setState({ proofingEmail: '' })
      }
    }
  }

  useEffect(() => {
    if (providersSelected.length > 0 && blacklisted_providers.findIndex(x => providerSelected.reference?.includes(x)) === -1 && providerSelected?.reference){
      blacklisted_providers.push(providerSelected?.reference)
        setEntityState({ blacklisted_providers })
    }
  }, [providersSelected])

  useEffect(() => {
    setState({ providersSelected: [...Object.values(usersV3).filter(x => entityState.blacklisted_providers?.includes(x.reference))] })
  }, [entityState.blacklisted_providers, usersV3])

  useEffect(() => {
    if (entityState.id === null) entityState.blacklisted_providers.splice(0, entityState.blacklisted_providers.length)
  }, [])

return (
  <DashboardModule title="Preferences" boxProps={{ marginBottom: 'large' }}>
    <Box flexDirection="column" padding="large">
      <Box flexDirection={['column', 'row']} marginBottom="large">
        <FormField
          boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
          label="Blacklisted Provider"
        >
          <Box flexDirection="row" width={['100%', 'calc(50% - 4px)']}>
            <InputSearch
              callbacks={{ selectItem: result => toggleProvider(result) }}
              onChange={e => setState({ searchFilter: e.target.value })}
              placeholder="Search Provider"
              value={searchFilter}
              closeOnSelect
              results={mapResults(filteredUsersV3, providersSelected)}
            />
          </Box>
          <Box flexWrap="wrap" gridGap="small" marginTop="medium">
            {providersSelected.sort((a, b) => (a > b ? 1 : -1)).map(provider => (
              <Tag boxProps={{ width: 'fit-content', fontSize: 'xsmall', onClick: () => toggleProvider(provider) }} key={provider.id}>
                <Text color="bodyFontLightColor">
                  {provider.name} &nbsp;<FontAwesomeIcon icon={faTimes} />
                </Text>
              </Tag>
            ))}
          </Box>
        </FormField>
      </Box>
      <Box flexDirection={['column', 'row']} marginBottom="large">
        <FormField
          boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
          label="Proofing Emails"
        >
          <Box flexDirection="row" alignItems="center">
            <Box flexDirection="row" width={['100%', 'calc(50% - 4px)']}>
              <input
                name="proofing_emails"
                type="text"
                value={proofingEmail}
                onChange={e => setState({ proofingEmail: e.target.value })}
                onKeyDown={e => (e.key === 'Enter' ? handleAddEmails(e.target.value, 'proofing') : null)}
              />
            </Box>
            <Tag
              boxProps={{
                backgroundColor: '#43ac6a!important',
                fontSize: 'xsmall',
                onClick: () => (proofingEmail ? handleAddEmails(proofingEmail, 'proofing') : null),
                width: 'fit-content',
                marginLeft: 'medium',
              }}
            >
              <Text color="white" fontSize={9} style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faPlus} />
              </Text>
            </Tag>
          </Box>

          <Box flexWrap="wrap" gridGap="small" marginTop="medium">
            {proofing_emails?.map(email => (
              <Tag
                boxProps={{
                  width: 'fit-content',
                  fontSize: 'xsmall',
                  onClick: () => setEntityState({
                    proofing_emails: proofing_emails?.filter(x => x !== email),
                  }),
                }}
                key={email}
              >
                <Text color="bodyFontLightColor">
                  {email} &nbsp;<FontAwesomeIcon icon={faTimes} />
                </Text>
              </Tag>
            ))}
          </Box>
        </FormField>
      </Box>
      <Box flexDirection={['column', 'row']} marginBottom="large">
        <FormField
          boxProps={{ flex: 1, paddingBottom: ['large', 0], marginRight: [0, 'medium'] }}
          label="Calendar Invite Emails"
        >
          <Box flexDirection="row" alignItems="center">
            <Box flexDirection="row" width={['100%', 'calc(50% - 4px)']}>
              <input
                name="calendar_invite_emails"
                type="text"
                value={calendarInviteEmail}
                onChange={e => setState({ calendarInviteEmail: e.target.value })}
                onKeyDown={e => (e.key === 'Enter' ? handleAddEmails(e.target.value, 'calendarInvite') : null)}
              />
            </Box>
            <Tag
              boxProps={{
                backgroundColor: '#43ac6a!important',
                fontSize: 'xsmall',
                onClick: () => (calendarInviteEmail ? handleAddEmails(calendarInviteEmail, 'calendarInvite') : null),
                width: 'fit-content',
                marginLeft: 'medium',
              }}
            >
              <Text color="white" fontSize={9} style={{ cursor: 'pointer' }}>
                <FontAwesomeIcon icon={faPlus} />
              </Text>
            </Tag>
          </Box>

          <Box flexWrap="wrap" gridGap="small" marginTop="medium">
            {calendar_invite_emails?.map(email => (
              <Tag
                boxProps={{
                  width: 'fit-content',
                  fontSize: 'xsmall',
                  onClick: () => setEntityState({
                    calendar_invite_emails: calendar_invite_emails?.filter(x => x !== email),
                  }),
                }}
                key={email}
              >
                <Text color="bodyFontLightColor">
                  {email} &nbsp;<FontAwesomeIcon icon={faTimes} />
                </Text>
              </Tag>
            ))}
          </Box>
        </FormField>
      </Box>
    </Box>
  </DashboardModule>
  )
}

Preferences.propTypes = {
  clientForm: PropTypes.object,
}

export default Preferences
