/* eslint-disable max-len */
import React, { useEffect, useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { toast } from 'react-toastify'

import { Box, Button, ListItem, SidebarNoticeBox } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import useServiceJob from '@hooks/useServiceJob'
import useUploadResource from '@hooks/useUploadResource'
import useUploadMultipleResource from '@hooks/useUploadMultipleResource'
import useCurrentUser from '@hooks/useCurrentUser'
import useServiceJobSetAvailable from '@hooks/useServiceJobSetAvailable'
import useServiceJobPushRaw from '@hooks/useServiceJobPushRaw'
import useServiceJobSendToEsoft from '@hooks/useServiceJobSendToEsoft'

import userTypes from '@functions/userTypes'
import prestigeServices from '@functions/prestigeServices'
import portraitServices from '@functions/portraitServices'
import droneServices from '@functions/droneServices'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCloudUpload, faPaperPlane } from '@fortawesome/pro-light-svg-icons'

const assignServiceJobStatus = (serviceJobParam, createFn) => {
  createFn(serviceJobParam).then(({ errors, success }) => {
    if (!success && errors){
      toast.warning(errors[0])
    }
  })
}

const Footer = (props) => {
  const { callbacks: { toggleCheckActionStatus }, isMissingResources, selectedIds } = props

  const { serviceJob } = useContext(PageContext)

  const useUploadResourcePayload = useUploadResource()
  const useUploadMultipleResourcePayload = useUploadMultipleResource()
  const {
    callbacks: {
      launchResourceUploadModal,
    },
  } = useUploadResourcePayload
  const {
    callbacks: {
      launchMultipleResourceUploadModal,
    },
  } = useUploadMultipleResourcePayload

  const {
    callbacks: {
      updateServiceJobTotalAssets: updateFn,
      assignServiceJobStatus: createFn,
    },
    creating,
    loading: serviceJobLoading,
    showDuskPositional,
  } = useServiceJob({ id: serviceJob.id })

  const { callbacks: { getServiceJobSetAvailable }, serviceJobSetAvailable, loading } = useServiceJobSetAvailable(serviceJob.id)
  const { callbacks: { postServiceJobPushRaw }, serviceJobPushRaw, loading: pushRawLoading  } = useServiceJobPushRaw()
  const { callbacks: { postServiceJobSendToEsoft }, serviceJobSendToEsoft, loading: sendToEsoftLoading, creating: sendToEsoftCreating } = useServiceJobSendToEsoft()
  const { currentUser } = useCurrentUser()

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups, serviceJobs } = entities

  const duskJob = Object.values(serviceJobs).filter(x => ['Dusk Photography'].includes(x.service?.name) && x.campaign.id === serviceJob.campaign.id)[0]
  const launchMultipleUploader = ['Photography'].includes(serviceGroups[serviceJob.serviceGroupId]?.name) && ['BackOffice', 'ProductionManager'].includes(currentUser.userRole?.name)

  const updateTotalImages = (isDusk) => {
    swal.fire({
      title: isDusk ? 'Update Dusk Positional Images' : 'Update Total Images',
      html: '<div style="overflow-x: auto;">'
        + '<table style="width:100%;table-layout:fixed;">'
        + '<tr>'
        + '<td style="text-align:right;width:40%;">Total Images:</td>'
        + `<td style="width:50%;"><input type="number" id="totalAssets" class="swal2-input" style="width:90%;" value="${isDusk ? duskJob?.totalAssets : serviceJob.totalAssets}"></td>`
        + '<td style="text-align:left;width:10%;color:red;">*</td>'
        + '</tr>'
        + '<tr>'
        + '<td style="text-align:right;width:40%;position:relative;bottom:70px;">Note: </td>'
        + '<td style="width:50%;"><textarea id="assetNote" class="swal2-input" style="width:90%;height:150px;margin:0px;" ></textarea></td>'
        + '<td style="text-align:left;width:10%;color:red;position:relative;bottom:70px;">*</td>'
        + '</tr>'
        + '</table>'
        + '</div>',
      icon: 'info',
      showCancelButton: false,
      confirmButtonText: 'Confirm',
      showClass: 'slide-from-top',
      padding: '0.6rem',
      preConfirm: () => {
        const inputNumber = swal.getHtmlContainer().querySelector('#totalAssets').value
        const inputNote = swal.getHtmlContainer().querySelector('#assetNote').value
        if (!inputNote) swal.showValidationMessage('Note is required when updating Total Images.')
        if (!inputNumber) swal.showValidationMessage('Please input the total images.')

        const obj = { totalAssets: inputNumber, TotalAssetsNote: inputNote }
        updateFn(isDusk ? duskJob : serviceJob, obj).then(({ success }) => success)
      },
      allowOutsideClick: false,
    }).then((success) => {
      if (success){
        if (launchMultipleUploader) launchMultipleResourceUploadModal()
        else launchResourceUploadModal()
        toggleCheckActionStatus('upload-resources')
      } else swal.showValidationMessage('Unable to update total images.')
    })
  }

  const confirmTotalDuskImages = () => {
    swal.fire({
      title: 'Confirm Dusk Positional Total',
      html: `This job requires ${duskJob?.totalAssets} dusk positional images. Please confirm if this is still correct.`,
      icon: 'question',
      showCancelButton: false,
      confirmButtonText: 'Yes',
      showDenyButton: true,
      denyButtonText: 'No',
      denyButtonColor: '#e2001a',
      showClass: 'slide-from-top',
      allowOutsideClick: false,
    }).then(({ value }) => {
      if (value){
        if (launchMultipleUploader) launchMultipleResourceUploadModal()
        else launchResourceUploadModal()
        toggleCheckActionStatus('upload-resources')
      } else updateTotalImages(true)
    })
}

  const confirmTotalImages = () => {
    if (!['Photography'].includes(serviceGroups[serviceJob.serviceGroupId]?.name) || !serviceJob.ctReferenceId){
      if (launchMultipleUploader) launchMultipleResourceUploadModal()
      else launchResourceUploadModal()
      toggleCheckActionStatus('upload-resources')
    } else {
      swal.fire({
        title: 'Confirm Total Images',
        html: `This job requires ${serviceJob.totalAssets} images.  Please confirm if this is still correct.`,
        icon: 'question',
        showCancelButton: false,
        confirmButtonText: 'Yes',
        showDenyButton: true,
        denyButtonText: 'No',
        denyButtonColor: '#e2001a',
        showClass: 'slide-from-top',
        allowOutsideClick: false,
      }).then(({ value }) => {
        if (value && showDuskPositional){
          confirmTotalDuskImages()
        } else if (value){
          if (launchMultipleUploader) launchMultipleResourceUploadModal()
          else launchResourceUploadModal()
          toggleCheckActionStatus('upload-resources')
        } else {
          updateTotalImages(false)
        }
      })
    }
  }

  const confirmResources = () => {
    swal.fire({
      title: 'Confirm Resources',
      html: 'Confirming will push the images to the customer. No further changes beyond this point.'
        + '<br/>Do you wish to continue?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
    }).then(({ value }) => {
      if (value){
        const serviceJobParam = {
          id: serviceJob.id,
          serviceJobStatusId: 60,
          workflowComment: 'Confirm from CH-Resources',
        }
        assignServiceJobStatus(serviceJobParam, createFn)
        toggleCheckActionStatus('confirm-resources')
      }
    })
  }

  const sendToClient = () => {
    var text = portraitServices.find(x => x.id === serviceJob.service?.id) ? 'This action will send these images to the client for Portrait Selection and an email will be sent to notify them.' : 'This action will send these images to the client for Drone Selection and an email will be sent to notify them.'
    swal.fire({
      title: ' Resources',
      html: text
        + '<br/>Do you wish to continue?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
    }).then(({ value }) => {
      if (value){
        const serviceJobParam = {
          id: serviceJob.id,
          serviceJobStatusId: 60,
          workflowComment: 'Send to Client from CH-Resources',
        }
        assignServiceJobStatus(serviceJobParam, createFn)
        toggleCheckActionStatus('confirm-resources')
      }
    })
  }

  const requestRaw = () => {
    var text = 'This action will send the selected images raw copies to Campaigntrack Studio.'
    swal.fire({
      title: 'Request RAW/JPG',
      html: text
        + '<br/>Do you wish to continue?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
    }).then(({ value }) => {
      if (value){
        const serviceJobParam = {
          resources: selectedIds,
        }
        postServiceJobPushRaw(serviceJob.id, serviceJobParam)
        toggleCheckActionStatus('confirm-resources')
      }
    })
  }

  const sendToEsoft = () => {
    var text = 'This action will send the selected images raw copies to Esoft.'
    swal.fire({
      title: 'Send to Esoft',
      html: text
        + '<br/>Do you wish to continue?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
    }).then(({ value }) => {
      if (value){
        const serviceJobParam = {
          resources: selectedIds,
        }
        postServiceJobSendToEsoft(serviceJob.id, serviceJobParam)
        toggleCheckActionStatus('confirm-resources')
      }
    })
  }

  const infoPrompt = () => {
    swal.fire({
      title: 'Job is already proofed',
      html: '<p>This job has already been proofed and additional resources will no longer be accommodated.</p>'
        + '<p>Please contact support@contenthouse.io so that they can assist you better.</p>',
      icon: 'info',
      confirmButtonText: 'Okay',
    })
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (serviceJob.serviceJobStatusId === 60 || !prestigeServices.find(x => x.id === serviceJob.service?.id)){
        clearInterval(interval)
      }
      else if (portraitServices.find(x => x.id === serviceJob.service?.id) || droneServices.find(x => x.id === serviceJob.service?.id)) {
          getServiceJobSetAvailable(serviceJob.id)
      } else { getServiceJobSetAvailable(serviceJob.id) }
    }, 30000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      {(currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'Studio').id
      && prestigeServices.find(x => x.id === serviceJob.service?.id)
      && (!serviceJobSetAvailable?.canConfirm || isMissingResources || serviceJob.serviceJobStatusId === 60)) && (
        <ListItem
          boxProps={{
          borderTop: '1px solid',
          padding: 'large',
          lineHeight: '1.3',
          alignItems: 'center',
        }}
          disableHover
        >
          <SidebarNoticeBox
            boxProps={{
            marginBottom: 'medium',
            backgroundColor: 'lightcyan!important',
            borderColor: 'rgb(125, 208, 215, 0.5)!important',
          }}
          >
            <SidebarNoticeBox.Title>
              {serviceJob.serviceJobStatusId === 60 ? 'Awaiting Selection' : (isMissingResources ? 'Current quantity on the Gallery does not match with the client order '
              : 'Current resources does not match with Jobtrack')}
            </SidebarNoticeBox.Title>
            <SidebarNoticeBox.Paragraph>
              {serviceJob.serviceJobStatusId === 60 ? 'This job has already been confirmed.' : (isMissingResources ? 'Please review the client\'s order and try again.'
              : 'Please wait for the syncing to be completed and try again.')}
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>
        </ListItem>
      )}
      {(currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'Studio').id
      && (portraitServices.find(x => x.id === serviceJob.service?.id) || droneServices.find(x => x.id === serviceJob.service?.id))
      && ( !serviceJobSetAvailable?.canConfirm)) && (
        <ListItem
          boxProps={{
          borderTop: '1px solid',
          padding: 'large',
          lineHeight: '1.3',
          alignItems: 'center',
        }}
          disableHover
        >
          <SidebarNoticeBox
            boxProps={{
            marginBottom: 'medium',
            backgroundColor: 'lightcyan!important',
            borderColor: 'rgb(125, 208, 215, 0.5)!important',
          }}
          >
            <SidebarNoticeBox.Title>
              {'Current resources does not match with Jobtrack'}
            </SidebarNoticeBox.Title>
            <SidebarNoticeBox.Paragraph>
              {'Please wait for the syncing to be completed and try again.'}
            </SidebarNoticeBox.Paragraph>
          </SidebarNoticeBox>
        </ListItem>
      )}
      <ListItem
        boxProps={{
        borderTop: '1px solid',
        padding: 'large',
        lineHeight: '1.3',
        alignItems: 'center',
      }}
        flexDirection="row"
        style={{ cursor: 'pointer' }}
        disableHover
      >
        <Box alignItems="center" marginRight="auto" justifyContent="flexStart" width="auto">
          <Button
            buttonStyle="secondary"
            icon={<FontAwesomeIcon icon={faCloudUpload} />}
            loading={serviceJobLoading}
            onClick={() => {
              serviceJob.serviceJobStatusId >= 150
              && !(['Floorplan'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)
                && serviceJob.serviceJobStatusId === 160
                && currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'Studio')?.id
              ) ? infoPrompt() : confirmTotalImages()
            }}
            size="medium"
          >
            Upload Resources
          </Button>
        </Box>
        {(currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'Studio').id && prestigeServices.find(x => x.id === serviceJob.service?.id)) && (
          <Box alignItems="center" marginLeft="medium" justifyContent="flexEnd" width="auto">
            <Button
              size="medium"
              buttonStyle="secondary"
              disabled={!serviceJobSetAvailable?.canConfirm || isMissingResources || serviceJob.serviceJobStatusId === 60}
              icon={<FontAwesomeIcon icon={faPaperPlane} />}
              onClick={confirmResources}
              loading={loading || creating}
            >
              Confirm
            </Button>
          </Box>
        )}
        {(currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'Studio').id && (portraitServices.find(x => x.id === serviceJob.service?.id) || droneServices.find(x => x.id === serviceJob.service?.id))) && (
          <Box alignItems="center" marginLeft="medium" justifyContent="flexEnd" width="auto">
            <Button
              size="medium"
              buttonStyle="secondary"
              disabled={serviceJob.serviceJobStatusId >= 150 || !serviceJobSetAvailable?.canConfirm}
              icon={<FontAwesomeIcon icon={faPaperPlane} />}
              onClick={sendToClient}
              loading={loading || creating}
            >
              Send to Client Gallery
            </Button>
          </Box>
        )}
        {(currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'Studio').id && (portraitServices.find(x => x.id === serviceJob.service?.id) || droneServices.find(x => x.id === serviceJob.service?.id))) && (
          <Box alignItems="center" marginLeft="medium" justifyContent="flexEnd" width="auto">
            <Button
              size="medium"
              buttonStyle="secondary"
              disabled={!serviceJobSetAvailable?.canConfirm}
              icon={<FontAwesomeIcon icon={faPaperPlane} />}
              onClick={requestRaw}
              loading={loading || creating}
            >
              Request RAW/JPG
            </Button>
          </Box>
        )}
        {(currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'Studio').id || currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'BackOffice').id) && (
          <Box alignItems="center" marginLeft="medium" justifyContent="flexEnd" width="auto">
            <Button
              size="medium"
              buttonStyle="secondary"
              disabled={serviceJob.serviceJobStatusId !== 50}
              icon={<FontAwesomeIcon icon={faPaperPlane} />}
              onClick={sendToEsoft}
              loading={sendToEsoftCreating}
            >
              Send to Esoft
            </Button>
          </Box>
        )}
      </ListItem>
    </>
  )
}

Footer.propTypes = {
  callbacks: PropTypes.object,
  isMissingResources: PropTypes.bool,
}

export default Footer
