/* eslint-disable no-console */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'

import JSZip from 'jszip'
import axios from 'axios'
import { saveAs } from 'file-saver'

import { Box, Button, Checkbox, Tag, Text } from '@campaignhub/suit-theme'
import { faSortAlphaDown, faSortAlphaUp, faFolderDownload } from '@fortawesome/pro-light-svg-icons'
import { faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import custom from '@styles/custom.module.scss'
import GridView from './GridView'

const defaulState = {
  showSortOptions: false,
  zipCount: 0,
  zipLoading: false,
}

const Gallery = (props) => {
  const {
    callbacks: {
      toggleSelectAllContent,
      toggleSort,
      toggleMultipleSelection,
      toggleUpdateSelectedIds,
    },
    contents,
    consolidateContent,
    disableAssetLink,
    galleryPlaceholder,
    maxSelection,
    multipleSelection,
    selectAll,
    selectedIds,
    serviceJob,
    showControls,
    showDetails,
    sortDirection,
    sortKey,
    type,
  } = props

  const [state, setState] = useSetState(defaulState)
  const { showSortOptions, zipCount, zipLoading } = state

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showSortOptions })

  useEffect(() => {
    if (isClickedOutside && showSortOptions){
      setState({ showSortOptions: !showSortOptions })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  const zip = new JSZip()
  const countRef = useRef()
  const totalRef = useRef()

  const download = item => axios.get(item.file.downloadUrl || item.assetDownloadUrl, { responseType: 'blob',
    onDownloadProgress: (progressEvent) => {
      // indication if the zip has unexpectedly stopped
      console.info(progressEvent.loaded)
    } }).then((resp) => {
        countRef.current.textContent = Object.values(zip.files).length
        zip.file(item.file.originalName, resp.data)
    })
    .catch((err) => {
      console.error(err)
      console.error(item)
    })

  const downloadAssets = (selectedAssets) => {
    setState({ zipLoading: true, zipCount: selectedAssets.length })
    const arrOfFiles = selectedAssets.map(item => download(item))

    Promise.all(arrOfFiles)
      .then(() => {
        zip.generateAsync({ type: 'blob' }, (metadata) => {
          countRef.current.textContent = metadata.percent.toFixed(0)
          totalRef.current.textContent = '% Processing Zip'
          }).then((blob) => {
              saveAs(blob, `${serviceJob.campaign.name}.zip`)
              setState({ zipLoading: false })
          })
      })
      .catch((err) => {
        console.error(err)
        setState({ zipLoading: false })
      })
  }

  return (
    <Box flexDirection="column" padding="large" className={custom.scroll}>
      {showControls && contents.length > 0 && (
        <Box flexDirection="row" paddingBottom="medium">
          <Box>
            <Button
              buttonStyle="secondary"
              disabled={selectedIds.length < 1}
              marginRight="medium"
              onClick={() => downloadAssets(contents.filter(x => selectedIds.includes(x.id)))}
              size="small"
              title="Download"
              width="auto"
            >
              {zipLoading && (
              <>
                <Text ref={countRef} marginRight="small"> 0 </Text>
                <Text ref={totalRef} marginRight="large"> / {zipCount} </Text>
              </>
                )}
              <FontAwesomeIcon icon={zipLoading ? faSpinner : faFolderDownload} className={zipLoading ? 'fa-spin' : ''} />
            </Button>
          </Box>
          <Box
            alignItems="center"
            flexShrink="0"
            justifyContent="flexEnd"
            marginLeft="auto"
            onClick={() => toggleMultipleSelection()}
            style={{ cursor: 'pointer' }}
            width="auto"

          >
            <Checkbox checked={multipleSelection} marginRight="medium" />
            <Text color="blue" fontSize="small">
              Select Multiple {type[0].toUpperCase()}{type.substring(1)}
            </Text>
          </Box>
          {multipleSelection && (contents.length <= maxSelection) && (
            <Box
              alignItems="center"
              flexShrink="0"
              justifyContent="flexEnd"
              marginLeft="large"
              onClick={() => toggleSelectAllContent()}
              style={{ cursor: 'pointer' }}
              width="auto"
            >
              <Checkbox checked={selectAll} marginRight="medium" />
              <Text color="blue" fontSize="small">
                Select All
              </Text>
            </Box>
          )}
          {multipleSelection && (contents.length !== maxSelection) && (
            <Box
              alignItems="center"
              flexShrink="0"
              justifyContent="flexEnd"
              marginLeft="large"
              onClick={() => toggleSelectAllContent()}
              style={{ cursor: 'pointer' }}
              width="auto"
            >
              <Text color="bodyFontLightColor" fontSize="small" marginRight="small">Max Selection: </Text>
              <Tag boxProps={{ width: 'fit-content', fontSize: 'xsmall' }}>{maxSelection}</Tag>
            </Box>
          )}
          <Box
            alignItems="center"
            color="blue"
            flexShrink="0"
            flexDirection="row"
            justifyContent="flexEnd"
            marginLeft="large"
            style={{ cursor: 'pointer' }}
            width="auto"
            className={custom.root}
            ref={contentEl}
          >
            <Box flexDirection="row">
              <Text color="blue" fontSize="small" marginRight="medium" onClick={() => setState({ showSortOptions: !showSortOptions })}>
                {sortKey === 'originalName' ? 'Name' : 'Upload Date'}
              </Text>
              <FontAwesomeIcon
                icon={sortDirection === 'asc' ? faSortAlphaUp : faSortAlphaDown}
                onClick={() => toggleSort(sortKey, sortDirection === 'asc' ? 'desc' : 'asc')}
                size="sm"
              />
            </Box>
            {showSortOptions && (
              <Box className={custom.popup} width="130px" style={{ right: '1px' }}>
                <Box flexDirection="column">
                  <Box
                    flexDirection="row"
                    padding="small"
                    className={custom.hover}
                    onClick={() => {
                      toggleSort('originalName', sortDirection)
                      setState({ showSortOptions: !showSortOptions })
                    }}
                  >
                    <Text color="bodyFontLightColor" fontSize="small">Name</Text>
                  </Box>
                  <Box
                    flexDirection="row"
                    padding="small"
                    className={custom.hover}
                    onClick={() => {
                      toggleSort('createdAt', sortDirection)
                      setState({ showSortOptions: !showSortOptions })
                    }}
                  >
                    <Text color="bodyFontLightColor" fontSize="small">Upload Date</Text>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}

      {contents.length > 0 && (
        <GridView
          callbacks={{ toggleUpdateSelectedIds }}
          consolidateContent={consolidateContent}
          contents={contents}
          disableAssetLink={disableAssetLink}
          selectedIds={selectedIds}
          maxSelection={maxSelection}
          multipleSelection={multipleSelection}
          showDetails={showDetails}
          sortDirection={sortDirection}
          sortKey={sortKey}
        />
      )}

      {!contents.length && (
        <Box
          alignItems="center"
          color="bodyFontColor"
          display="grid"
          height={[150, 250]}
          justifyContent="center"
        >
          <Text fontSize="small" fontWeight="600">
            {galleryPlaceholder || 'No available images.'}
          </Text>
        </Box>
      )}
    </Box>
  )
}

Gallery.propTypes = {
  callbacks: PropTypes.object,
  consolidateContent: PropTypes.object,
  contents: PropTypes.array,
  disableAssetLink: PropTypes.bool,
  galleryPlaceholder: PropTypes.string,
  maxSelection: PropTypes.number,
  multipleSelection: PropTypes.bool,
  selectAll: PropTypes.bool,
  selectedIds: PropTypes.array,
  serviceJob: PropTypes.object,
  showControls: PropTypes.bool,
  showDetails: PropTypes.bool,
  sortDirection: PropTypes.string,
  sortKey: PropTypes.string,
  type: PropTypes.string,
}

export default Gallery
