import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { useWatchEntityUpdates } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'

const watchEntityKeys = ['serviceJobUsers']

const useServiceJobUsers = (serviceJobId) => {
  const {
    updatedEntities: { serviceJobUsers: serviceJobUsersUpdatedAt },
  } = useWatchEntityUpdates(watchEntityKeys)

  const includes = ['serviceJobUserRole', 'user']
  const options = {
    includes: includes.join(','),
    serviceJobs: serviceJobId,
  }

  useReduxAction('serviceJobUsers', 'loadServiceJobUsers', options, [], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return !loaded && !loading && !errors.length
    },
  })

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobUsers } = entities

  const filteredServiceJobUsers = useMemo(() => {
    const filtered = Object.values(serviceJobUsers).filter(serviceJobUser => serviceJobUser.serviceJobId === serviceJobId)

    return filtered
  }, [serviceJobUsersUpdatedAt, serviceJobId])

  return {
    serviceJobUsers,
    filteredServiceJobUsers,
  }
}

export default useServiceJobUsers
